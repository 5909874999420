import { useConfig } from '@travelwin/core';

import AppStoreBadge from './AppStoreButton.svg';
import GooglePlayBadge from './GooglePlayButton.svg';

const MobileStoreButton = ({
  url,
  badgeImage,
  onClick,
}: {
  url: string;
  badgeImage: string;
  onClick?: () => void;
}) => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer" onClick={onClick}>
      <img src={badgeImage} alt="" />
    </a>
  );
};

type Props = {
  onClick?: () => void;
};

export const AppStore = ({ onClick }: Props) => {
  const { APPLE_STORE_APP_URL } = useConfig();

  if (!APPLE_STORE_APP_URL) {
    return null;
  }

  return (
    <MobileStoreButton
      url={APPLE_STORE_APP_URL}
      badgeImage={AppStoreBadge}
      onClick={onClick}
    />
  );
};

export const GooglePlayStore = ({ onClick }: Props) => {
  const { GOOGLE_PLAY_APP_URL } = useConfig();

  if (!GOOGLE_PLAY_APP_URL) {
    return null;
  }

  return (
    <MobileStoreButton
      url={GOOGLE_PLAY_APP_URL}
      badgeImage={GooglePlayBadge}
      onClick={onClick}
    />
  );
};
